/* Remove default bullets

ul
  list-style-type: none

#myUL
  list-style-type: none
  margin: 0
  padding: 0

/* Remove margins and padding from the parent ul

/* Style the caret/arrow

.caret
  cursor: pointer
  user-select: none

  /* Prevent text selection

  &::before
    content: "\25B6"
    color: black
    display: inline-block
    margin-right: 6px

/* Create the caret/arrow with a unicode, and style it

/* Rotate the caret/arrow icon when clicked on (using JavaScript)

.caret-down::before
  transform: rotate(90deg)

/* Hide the nested list

.nested
  display: none

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript)

.active
  display: block

.margin-top
  margin-top: 10px

.padding
  padding: 10px

.right-button
  float: right

.load
  margin-top: 25px