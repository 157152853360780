#container
  margin: auto
  text-align: center

.txt-clr
  color: green

.alert
  margin-top: 5px


