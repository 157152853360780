.tiles
  background: #b00909

.card
  /* Add shadows to create the "card" effect
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
  transition: 0.3s
  height: 100%
  margin-top: 20px

  &:hover
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2)

/* On mouse-over, add a deeper shadow

/* Add some padding inside the card container

.container2
  margin-left: 38%
  color: #b00909

.icon
  margin-top: 40px


/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect

.flip-card
  background-color: transparent
  width: 100%
  height: 200px
  //border: 1px solid #f1f1f1
  perspective: 1000px
  margin-top: 20px

/* Remove this if you don't want the 3D effect

/* This container is needed to position the front and back side

.flip-card-inner
  position: relative
  width: 100%
  height: 100%
  text-align: center
  transition: transform 0.5s
  transform-style: preserve-3d

/* Do an horizontal flip when you move the mouse over the flip box container

.flip-card:hover .flip-card-inner
  transform: rotateY(180deg)

/* Position the front and back side

.flip-card-front, .flip-card-back
  position: absolute
  width: 100%
  height: 100%
  -webkit-backface-visibility: hidden

  /* Safari
  backface-visibility: hidden

/* Style the front side (fallback if image is missing)

.flip-card-front
  background-color: #b00909
  color: white

/* Style the back side

.flip-card-back
  background-color: white
  border: #b00909 solid
  //#b4b2b2
  color: black
  transform: rotateY(180deg)

.margin-top
  margin-top: 10px

.padding
  padding: 10px

.text-color
  color: #b00909

.right
  position: absolute
  right: 0
  margin-right: 10px

.load
  margin-top: 25px
