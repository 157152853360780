.col-border
  border-bottom: 3px solid red
//border-radius: 5px

.headings-color
  color: black

.pipeline-size
  height: 400px
  width: 600px!important
