.home-btn
  margin-top: 70px

.btn-clr
  color: black

.btn-clr:hover
  color: red

.border-btm
  border-bottom: red solid

img
  height: 100px
  width: 180px
  cursor: pointer

img.margin-left
  margin-left: 70px
  //margin-left: 110px
