.accordion-layout
  margin-top: 15px

.text-border
  border: #b00909 solid

.wrap
  overflow-wrap: break-word

a.web-search:link
  text-decoration: none

a.web-search:hover
  text-decoration: underline

ul
  list-style: none
  /* Remove default bullets
  li::before
    content: "\2022"
    /* Add content: \2022 is the CSS Code/unicode for a bullet
    color: #f32323
    /* Change the color
    font-weight: bold
    /* If you want it to be bold
    display: inline-block
    /* Needed to add space between the bullet and the text
    width: 1em
    margin-left: -1em

//ol
//  list-style: none
//  counter-reset: li
//  li::before
//    content: counter(li)
//    counter-increment: li
//    color: black
//    display: inline-block
//    width: 1.5em
//    margin-left: -1.5em
//    ////margin-right: 0.5em
//    //text-align: left
//    //direction: ltr

.json-style
  font-size: 15px

td
  width: 5px !important
  padding-left: 3px

.right-button
  float: right

.margin-top
  margin-top: 10px

.margin-left
  margin-left: 10px

.parent > li
  border-bottom: 1px dotted black

.title
  color: #D27D2D
.hover-title
  color: #C4A484
.volume
  color: darkblue
.issue
  color: deeppink
.year
  color: darkgoldenrod
.type
  color: blueviolet
.source
  color: darkcyan
.pages
  color: orangered
.authors
  color: green
.editors
  color: purple
.publishers
  color: #5e5e05
.place
  color: saddlebrown


