.nav-icon
  margin-left: 5px

.center
  width: 50%
  margin: auto

.margin-top
  margin-top: 10px

.filters-padding
  padding-inline: 20px

.none-display
  display: none

.background-clr
  background-color: #ffffff

.load
  margin-top: 25px